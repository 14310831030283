<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>任务定义</template>
    </breadcrumb-nav>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="图标" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="120px">
      </el-table-column>
      <el-table-column prop="desc" label="描述">
      </el-table-column>
      <el-table-column prop="state" label="任务状态">
        <template slot-scope="{row}">
          <el-switch v-model="row.state" active-text="上架" inactive-text="下架" :active-value=1
                     :inactive-value=2 @change="changeState(row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="taskType" label="类型" width="100px">
        <template slot-scope="scope">{{ scope.row.taskType | taskTypeLabel }}</template>
      </el-table-column>
      <el-table-column prop="subType" label="子类型" width="100px">
        <template slot-scope="scope">{{ scope.row.subType | subTypeLabel }}</template>
      </el-table-column>
      <el-table-column prop="targetVal" label="次数" width="80px">
      </el-table-column>
      <el-table-column prop="rewardVal" label="奖励" width="80px">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="200px;">
        <template slot-scope="{row, $index}">
          <el-row>
          <el-button
            type="warning"
            icon="el-icon-edit"
            size="mini"
            @click="updateTask(row)"
          >修改</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="delTask(row)"
          >删除</el-button
          >
            </el-row>
          <el-row>
          <el-button icon="el-icon-top" style="margin-left: 0;margin-top:10px;" type="success" size="mini" @click="upindex($index,row)">上移</el-button>
          <el-button icon="el-icon-bottom" type="success" size="mini"  @click="downindex($index,row)">下移</el-button>
            </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="taskForm.id ? '修改任务' : '添加任务'"
      :visible.sync="dialogFormVisible"
    >
      <!-- form表单 :model属性，这个属性的作用是,把表单的数据收集到那个对象的身上 ，将来表单验证，也需要这个属性-->
      <el-form style="width: 80%" :model="taskForm" :rules="rules" ref="taskForm">
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="taskForm.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="desc">
          <el-input v-model="taskForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="类型" label-width="100px" prop="taskType">
          <el-select v-model="taskForm.taskType" style="width:100%" placeholder="请选择任务类型">
            <el-option
              v-for="item in taskTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子类型" label-width="100px" prop="subType">
          <el-select v-model="taskForm.subType" style="width:100%" placeholder="请选择子类型">
            <el-option
              v-for="item in subTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="次数" label-width="100px" prop="targetVal">
          <el-input type="Number" v-model.number="taskForm.targetVal"></el-input>
        </el-form-item>
        <el-form-item label="奖励" label-width="100px" prop="rewardVal">
          <el-input type="Number" v-model.number="taskForm.rewardVal"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="icon">
          <!--这里收集数据：不能使用v-model，因为不是表单元素
            action:设置图片上传的地址
            :on-success:可以检测到图片上传成功，当图片上传成功，会执行一次
            :before-upload：可以在上传图片之前，会执行一次

          -->
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateTask"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import {
  updateTaskRequest,
  addTaskRequest,
  getTaskListRequest,
  delTaskRequest,
  SortTaskRequest,
  UpdateTaskStateRequest
} from '../../network/task'

export default {
  name: 'Task',
  components: {
    BreadcrumbNav
  },
  filters: {
    taskTypeLabel (value) {
      switch (value) {
        case 1:
          return '每日任务'
        case 2:
          return '成就任务'
      }
    },
    subTypeLabel (value) {
      switch (value) {
        case 2:
          return '看视频'
        case 3:
          return '看原生广告'
        case 4:
          return '分享'
        case 5:
          return '邀请好友'
        case 6:
          return '购物'
        case 7:
          return '大转盘'
        case 8:
          return '拆红包'
        case 9:
          return '夺宝'
        case 10:
          return '看插屏广告'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: false,
      // 收集签到规则信息
      taskForm: {
        icon: '',
        name: '',
        desc: '',
        taskType: '',
        subType: '',
        targetVal: '',
        rewardVal: ''
      },
      // 表单验证规则
      rules: {
        // require:必须要校验字段（前面五角星有关系）  message 提示信息    trigger:用户行为设置（事件的设置:blur、change）
        name: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ],
        //
        desc: [{ required: true, message: '请输入任务描述', trigger: 'blur' }],
        taskType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        subType: [
          { required: true, message: '请选择子类型', trigger: 'change' }
        ],
        targetVal: [
          { required: true, message: '请输入目标值', trigger: 'blur' }
        ],
        rewardVal: [
          { required: true, message: '请输入奖励', trigger: 'blur' }
        ],
        icon: [{ required: true, message: '请选择任务图标' }]
      },
      // 任务类型
      taskTypes: [{
        label: '每日任务',
        value: 1
      }, {
        label: '成就任务',
        value: 2
      }],
      // 任务子类型
      subTypes: [{
        label: '看视频',
        value: 2
      }, {
        label: '看原生广告',
        value: 3
      }, {
        label: '分享',
        value: 4
      }, {
        label: '邀请好友',
        value: 5
      }, {
        label: '购物',
        value: 6
      }, {
        label: '大转盘',
        value: 7
      }, {
        label: '拆红包',
        value: 8
      }, {
        label: '抽大奖',
        value: 9
      }, {
        label: '看插屏广告',
        value: 10
      }]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getTaskList()
  },
  methods: {
    getTaskList () {
      getTaskListRequest().then(res => {
        const result = res.data
        console.log('task list:', result.data.list)
        if (res.status !== 200) {
          return this.alertMessage('获取规则列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
        }
        this.total = result.data.total
      })
    },
    updateTask (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      // 将已有的规则信息赋值给ruleForm进行展示
      // 将服务器返回规则的信息，直接赋值给了ruleForm进行展示。
      // 也就是ruleForm存储即为服务器返回规则信息
      this.taskForm = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.taskForm.icon
    },
    delTask (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTaskRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getTaskList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.taskForm = { icon: '', name: '', desc: '', taskType: '', subType: '', targetVal: '', rewardVal: '' }
      this.imageUrl = ''
    },
    updateTask2Srv () {
      updateTaskRequest(this.taskForm).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改任务失败', 'error')
        }
        this.alertMessage('修改任务成功', 'success')
        this.getTaskList()
      })
    },
    addTask2Srv () {
      addTaskRequest(this.taskForm).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加任务失败', 'error')
        }
        this.alertMessage('添加任务成功', 'success')
        this.getTaskList()
      })
    },
    addOrUpdateTask () {
      this.$refs.taskForm.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        console.log('this.taskForm:', this.taskForm)
        if (this.taskForm.id) {
          this.updateTask2Srv()
        } else {
          this.addTask2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.taskForm.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.taskForm.icon
    },
    handleImgRemove (file) {
      this.taskForm.icon = ''
      this.imageUrl = ''
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.list[index]
        const upData = this.list[index - 1]
        const sortTask = []
        sortTask.push({ taskId: curData.id, sortNum: upData.sortNum })
        sortTask.push({ taskId: upData.id, sortNum: curData.sortNum })
        SortTaskRequest({ list: sortTask }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.list.splice(index - 1, 1)
          this.list.splice(index, 0, upData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.list.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.list[index + 1]
        const curData = this.list[index]
        const sortTask = []
        sortTask.push({ taskId: curData.id, sortNum: downData.sortNum })
        sortTask.push({ taskid: downData.id, sortNum: curData.sortNum })
        SortTaskRequest({ list: sortTask }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.list.splice(index + 1, 1)
          this.list.splice(index, 0, downData)
        })
      }
    },
    changeState (row) {
      UpdateTaskStateRequest({ id: row.id, state: row.state }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改任务状态失败', 'error')
        }
        this.alertMessage('修改任务状态成功', 'success')
        this.getTaskList()
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
