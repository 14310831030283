import { request } from './request'

// 获取任务列表
export function getTaskListRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/task/list',
    method: 'get',
    params
  })
}

// 删除任务
export function delTaskRequest (id) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: `/task/${id}`,
    method: 'delete'
  })
}

// 添加任务
export function addTaskRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/task',
    method: 'post',
    data: { ...info }
  })
}

// 修改任务
export function updateTaskRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/task',
    method: 'put',
    data: { ...info }
  })
}

// 修改任务状态
export function UpdateTaskStateRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/task/state',
    method: 'put',
    data: { ...info }
  })
}

// 更改任务顺序
export function SortTaskRequest (data) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/task/sort',
    method: 'post',
    data
  })
}

// 获取奖励列表
export function getRewardListRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/list/reward',
    method: 'get',
    params
  })
}

// 获取奖励详情
export function getRewardDetailRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/reward/detail',
    method: 'get',
    params
  })
}

// 更改奖励状态
export function UpdateRewardStatusRequest (data) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/update/reward/status',
    method: 'post',
    data
  })
}

// 获取今日统计
export function GetStatTodayRequest (data) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/stat/lottery/total',
    method: 'post',
    data
  })
}

export function listStatLotteryHistory (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/stat/lottery/history',
    method: 'get',
    params
  })
}

export function listStatLotteryCount (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/stat/lottery/count',
    method: 'get',
    params
  })
}

export function getRandShareConf (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/share/conf/select',
    method: 'get',
    params
  })
}

// 获取审核列表
export function getRewardAuditListRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/reward/inivte/audit/list',
    method: 'get',
    params
  })
}

// 获取审核详情
export function getRewardAuditDetailRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/reward/inivte/audit/get',
    method: 'get',
    params
  })
}

// 执行审核
export function OperateRewardAuditRequest (data) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/reward/inivte/audit/operate',
    method: 'post',
    data
  })
}
